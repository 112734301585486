import React from "react";
import styled from "styled-components";
import { withPosition } from "./PositionContext";

const NameAnchor = styled.div`
  position: absolute;
  top: 0px;
  left: 72px;
  transform-style: inherit;
  transition: opacity 2.4s, transform 2.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  transform-origin: -8px center;
`;

const Title = styled.h2`
  width: 64px;
  margin-top: 16px;
  color: white;
  font-family: "Dosis", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.36);
`;

const isInbound = (x) => {
  if (x < 0.3) {
    return "before";
  }
  if (x > 0.7) {
    return "after";
  }
  return "in";
};

const transZ = {
  before: -0.1,
  in: 0,
  after: 0.1,
};

const opacities = {
  before: 0,
  after: 0,
  in: 1,
};

const rotY = {
  before: -180,
  in: 0,
  after: 180,
};

class Name extends React.Component {
  shouldComponentUpdate(nextProp) {
    return (
      isInbound(this.props.position.relY) !== isInbound(nextProp.position.relY)
    );
  }

  render() {
    const {
      position: { relY },
      gradient,
    } = this.props;
    const inbound = isInbound(relY);
    return (
      <NameAnchor
        style={{
          opacity: opacities[inbound],
          transform: `rotateY(${rotY[inbound]}deg) translateZ(${transZ[inbound]}px)`,
        }}
      >
        <Title
          style={{
            background: `linear-gradient(${gradient.start}, ${gradient.end})`,
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
          }}
        >
          {this.props.name}
        </Title>
      </NameAnchor>
    );
  }
}

export default withPosition(Name);
