// import cover from './render.2.jpg';
import cover from "./ra.mp4";

export default {
  name: "worldview",
  cover,
  coverType: "video",
  desc:
    "a smart window that helps people waking up in the morning and improves home working moods in a natural way",
  type: ["student work", "cooperative"],
  tech: [
    "Arduino",
    "Prototyping",
    "Storyboard",
    "Rhinoceros 3D",
    "Photoshop",
    "Keyshot",
    "V-Ray",
    "3D printing",
  ],
  instructor: ["Carla Diana"],
  author: ["Yihui Gan", "Cherag Bhagwagar", "Yuheng Ouyang"],
};
