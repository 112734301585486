import React from "react";
import styled from "styled-components";

const Title = styled.div`
  font-family: "Dosis", sans-serif;
  font-weight: 200;
  margin-top: 64px;
  margin-left: 64px;
  margin-right: 64px;
  font-size: 64px;
  color: rgba(0, 0, 0, 0.72);
`;

const Desc = styled.div`
  margin-left: 64px;
  margin-right: 64px;
`;

const Meta = styled.div`
  margin: 32px 64px;
`;

const BaseText = styled.p`
  font-family: "Roboto", sans-serif;
  margin: 0;
  @media (min-width: 960px) {
    width: 30vw;
  }
`;

const DescText = styled(BaseText)`
  font-size: 20px;
  font-weight: 300;
`;

const RegularText = styled(BaseText)`
  font-size: 16px;
  color: rgba(0, 0, 0, 0.96);
  font-weight: 300;
`;

const BoldText = styled(BaseText)`
  font-weight: 400;
`;

const VideoFrame = styled.video.attrs({
  autoPlay: true,
  loop: true,
  muted: true,
  playsInline: true,
})`
  position: absolute;
  object-fit: cover;
  object-position: 50% 50%;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  z-index: -1;
`;

const Item = ({ project }) => {
  const {
    name,
    colorGradient,
    cover,
    coverType,
    desc,
    type,
    tech,
    author,
    instructor,
  } = project;
  return (
    <div
      className="background"
      style={{
        backgroundColor: colorGradient.end,
        backgroundImage: coverType === "video" ? null : `url(${cover})`,
      }}
    >
      {coverType === "video" && <VideoFrame src={cover} />}
      <Title>{name}</Title>
      {desc && (
        <Desc>
          <DescText>{desc}</DescText>
        </Desc>
      )}
      {Object.entries({ instructor, author, type, tech }).map(
        ([title, value]) =>
          value && (
            <Meta>
              <BoldText>{title}</BoldText>
              {value.map((name) => (
                <RegularText key={name}>{name}</RegularText>
              ))}
            </Meta>
          )
      )}
    </div>
  );
};

export default Item;
