import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { RouterWrapper } from './RouterWrapper';
import { MouseWrapper } from './MouseWrapper';
import { ScrollWrapper } from './ScrollWrapper';
import { IndexWrapper } from './IndexWrapper';

const Overmind = props => (
  <Router>
    <RouterWrapper>
      <IndexWrapper>
        <ScrollWrapper>
          <MouseWrapper>{props.children}</MouseWrapper>
        </ScrollWrapper>
      </IndexWrapper>
    </RouterWrapper>
  </Router>
);

export default Overmind;
