import React from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { withPosition } from "./PositionContext";

const Dot = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 32px;
  transition: transform 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
  transform-style: inherit;
`;

const InnerText = styled.p`
  top: 20px;
  left: 0px;
  width: 64px;
  text-align: center;
  color: white;
  font-family: "Roboto";
  margin: 0px;
  position: absolute;
  transition: transform 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
`;

class Circle extends React.Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.activeIndex !== nextProps.index && prevState.active) {
      return { active: false };
    }
    return null;
  }
  state = {
    active: false,
  };
  shouldComponentUpdate(nextProps) {
    return nextProps.position.live;
  }
  render() {
    const {
      setIndex,
      position: { relY },
      gradient,
      index,
      history,
      path,
    } = this.props;
    const { active } = this.state;
    const offset = 1 - Math.abs(0.5 - relY) * 2;
    const opacity = offset ** 2 * 0.95 + 0.05;
    const handleEnter = () => {
      setIndex(index);
    };
    const handleLeave = () => {
      setIndex(-1);
    };
    const handleClick = () => {
      if (path) {
        setIndex(-1);
        history.push(path);
      }
    };
    const handleTouch = (e) => {
      e.preventDefault();
      if (active) {
        handleClick();
      } else {
        setIndex(index);
        this.setState({ active: true });
      }
    };
    return (
      <Dot
        onMouseEnter={handleEnter}
        onMouseLeave={handleLeave}
        onTouchStart={(e) => {
          e.stopPropagation();
        }}
        onTouchEnd={handleTouch}
        onClick={handleClick}
        style={{
          cursor: path ? "pointer" : null,
          background: `linear-gradient(${gradient.start}, ${gradient.end})`,
          opacity,
          transform: active ? "translateZ(32px)" : null,
        }}
      >
        <InnerText
          style={{
            display: active ? "block" : "none",
            transform: `translateZ(${active ? 16 : 0}px)`,
          }}
        >
          {path ? "ENTER" : "WIP"}
        </InnerText>
      </Dot>
    );
  }
}

export default withRouter(withPosition(Circle));
