import cover from "./video.mp4";

export default {
  name: "in-store 3D",
  cover,
  coverType: "video",
  desc: "in-store 3D navigation on web",
  type: ["commercial", "cooperative"],
  tech: ["Unity 3D", "react-unity-webgl", "RESTful API", "Rhinoceros 3D"],
  author: ["Nilay Chakraborty", "Yuheng Ouyang"],
};
