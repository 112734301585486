import React from "react";
import { IndexConsumer } from "../Contexts";
import Circle from "./Circle";
import Name from "./Name";

class Item extends React.Component {
  render() {
    // console.log('item reredndered');
    return (
      <React.Fragment>
        <IndexConsumer>
          {(index) => (
            <Circle
              gradient={this.props.project.colorGradient}
              path={this.props.project.path}
              index={this.props.index}
              activeIndex={index.index}
              setIndex={index.setIndex}
            />
          )}
        </IndexConsumer>
        <Name
          gradient={this.props.project.colorGradient}
          name={this.props.project.name}
        />
      </React.Fragment>
    );
  }
}

export default Item;
