import cover from "./Render_Axon_whiteBG.jpg";
import oneRule from "./ElementaryCA30Rules_750.gif";
import twoD from "./Comb.svg";
import twoGensGif from "./0-127.gif";
import twoGens from "./0-127frame.jpg";
import f59 from "./f59.gif";
import f65 from "./f65.gif";
import f86 from "./f86.gif";
import f89 from "./f89.gif";
import f113 from "./f113.gif";
import gr from "./gr.gif";
import pointtolink from "./pointtolink.png";
import linking from "./linking.png";
import cb1 from "./cb1.jpg";
import plan1 from "./plan1.png";
import plan2 from "./plan2.png";
import d1d from "./1d.jpg";
import near from "./Render_Near_cropped.jpg";
import r2 from "./r2.jpg";
import r3 from "./r3.jpg";
import r4 from "./r4.jpg";
import k2 from "./k2.jpg";

export default {
  name: "hellenikon solid",
  cover,
  desc: "architectural geometry studies of cellular automata rulesets",
  type: ["student work", "cooperative"],
  tech: ["Rhinoceros 3D", "Grasshopper", "Python", "V-Ray"],
  author: ["Jiahua Xu", "Yuheng Ouyang"],
  instructor: ["Ezio Blasetti", "Cecil Balmond"],
  path: "/hellenikonsolid",
  pageBackgroundColor: "white",
  content: [
    {
      type: "image",
      value: cover,
      classes: "full",
    },
    {
      type: "paragraph",
      value:
        "A cellular automaton is a discrete model studied in computability theory, mathematics, physics, complexity science, theoretical biology and microstructure modeling. Cellular automata are also called cellular spaces, tessellation automata, homogeneous structures, cellular structures, tessellation structures, and iterative arrays. A cellular automaton consists of a regular grid of cells, each in one of a finite number of states, such as on and off (in contrast to a coupled map lattice). The grid can be in any finite number of dimensions. For each cell, a set of cells called its neighborhood is defined relative to the specified cell. An initial state (time t = 0) is selected by assigning a state for each cell. A new generation is created (advancing t by 1), according to some fixed rule (generally, a mathematical function) that determines the new state of each cell in terms of the current state of the cell and the states of the cells in its neighborhood. Typically, the rule for updating the state of cells is the same for each cell and does not change over time, and is applied to the whole grid simultaneously, though exceptions are known, such as the stochastic cellular automaton and asynchronous cellular automaton. (from Wikipedia)",
    },
    {
      type: "image",
      value: oneRule,
      classes: "quarter",
    },
    {
      type: "caption",
      value: "1D CA ruleset example (from Wolfram)",
    },
    {
      type: "image",
      value: d1d,
      classes: "quarter3",
    },
    {
      type: "caption",
      value: "1D CA | left: rule 1 - 128 | right: rule 129 - 256",
    },
    {
      type: "image",
      value: twoD,
      classes: "quarter",
    },
    {
      type: "caption",
      value: "2D cellular automaton ruleset example",
    },
    {
      type: "image",
      value: twoGens,
      classes: "quarter3",
    },
    {
      type: "image",
      value: twoGensGif,
      classes: "quarter",
    },
    {
      type: "caption",
      value: "2D CA | rule 90:0101 | generation 1 - 128",
    },
    {
      type: "image",
      value: f59,
      classes: "quarter",
    },
    {
      type: "caption",
      value: "freezing on gen 59 | generation 1-24",
    },
    {
      type: "image",
      value: f65,
      classes: "quarter",
    },
    {
      type: "caption",
      value: "freezing on gen 65 | generation 1-24",
    },
    {
      type: "image",
      value: f86,
      classes: "quarter",
    },
    {
      type: "caption",
      value: "freezing on gen 86 | generation 1-24",
    },
    {
      type: "image",
      value: f89,
      classes: "quarter",
    },
    {
      type: "caption",
      value: "freezing on gen 89 | generation 1-24",
    },
    {
      type: "image",
      value: f113,
      classes: "quarter",
    },
    {
      type: "caption",
      value: "freezing on gen 113 | generation 1-24",
    },
    {
      type: "image",
      value: gr,
      classes: "quarter",
    },
    {
      type: "caption",
      value: "co-growth",
    },
    {
      type: "image",
      value: plan1,
      classes: "half",
    },
    {
      type: "caption",
      value: "plan 01",
    },
    {
      type: "image",
      value: plan2,
      classes: "half",
    },
    {
      type: "caption",
      value: "plan 02",
    },
    {
      type: "image",
      value: pointtolink,
      classes: "quarter3",
    },
    {
      type: "caption",
      value: "group generated point cloud into cubics then iterate",
    },
    {
      type: "image",
      value: linking,
      classes: "half",
    },
    {
      type: "caption",
      value: "convert grouped points to geometry using convex hull",
    },
    {
      type: "image",
      value: cb1,
      classes: "half",
    },
    {
      type: "caption",
      value: "accumulative 2D CA | co-growth | convex hull",
    },
    {
      type: "image",
      value: near,
      classes: "half",
    },
    {
      type: "image",
      value: r2,
      classes: "half",
    },
    {
      type: "image",
      value: r3,
      classes: "half",
    },
    {
      type: "image",
      value: r4,
      classes: "half",
    },
    {
      type: "image",
      value: k2,
      classes: "half",
    },
  ],
};
