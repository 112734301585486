import cover from "./702a90.jpg";
import i4d1 from "./4d1.jpg";
import i4d2 from "./4d2.jpg";
import i30 from "./30.jpg";
import i129 from "./129.jpg";
import i169 from "./169.jpg";
import i225 from "./225.jpg";
import circlePt from "./circlePt.gif";
import dr from "./dr.png";

export default {
  name: "ca.shell",
  cover,
  desc:
    "using elementary cellular automaton rulesets and spherical projection to generate complex geometries",
  type: ["student work", "independent"],
  tech: ["Rhinoceros 3D", "Grasshopper", "Python", "Keyshot", "Photoshop"],
  author: ["Yuheng Ouyang"],
  instructor: ["Ezio Blasetti"],
  path: "/cashell",
  pageBackgroundColor: "white",
  content: [
    {
      type: "image",
      value: cover,
      classes: "full nomargin",
    },
    {
      type: "paragraph",
      value:
        "A cellular automaton is a discrete model studied in computability theory, mathematics, physics, complexity science, theoretical biology and microstructure modeling. Cellular automata are also called cellular spaces, tessellation automata, homogeneous structures, cellular structures, tessellation structures, and iterative arrays. A cellular automaton consists of a regular grid of cells, each in one of a finite number of states, such as on and off (in contrast to a coupled map lattice). The grid can be in any finite number of dimensions. For each cell, a set of cells called its neighborhood is defined relative to the specified cell. An initial state (time t = 0) is selected by assigning a state for each cell. A new generation is created (advancing t by 1), according to some fixed rule (generally, a mathematical function) that determines the new state of each cell in terms of the current state of the cell and the states of the cells in its neighborhood. Typically, the rule for updating the state of cells is the same for each cell and does not change over time, and is applied to the whole grid simultaneously, though exceptions are known, such as the stochastic cellular automaton and asynchronous cellular automaton. (from Wikipedia)",
    },
    {
      type: "image",
      value: dr,
      classes: "half",
    },
    {
      type: "image",
      value: circlePt,
      classes: "quarter",
    },
    {
      type: "image",
      value: i30,
      classes: "half",
    },
    {
      type: "image",
      value: i129,
      classes: "half",
    },
    {
      type: "image",
      value: i169,
      classes: "half",
    },
    {
      type: "image",
      value: i225,
      classes: "half",
    },
    {
      type: "image",
      value: i4d1,
      classes: "half",
    },
    {
      type: "image",
      value: i4d2,
      classes: "half",
    },
  ],
};
