import cover from "./602r0.jpg";
import map from "./Map2withText.jpg";
import dia1 from "./dia1.png";
import dia2 from "./dia2.png";
import cut from "./cut.jpg";
import section from "./section.jpg";
import plan from "./plan.jpg";
import m1 from "./IMG_4281.jpg";
import m2 from "./IMG_4349.jpg";
import m3 from "./RenderLongWeb.jpg";
import r1 from "./602r2.jpg";
import r2 from "./render2.jpg";
import r3 from "./render4.jpg";

export default {
  name: "studio 602",
  cover,
  desc:
    "an architectural work discussing the relationship of interior and exterior",
  type: ["student work", "cooperative"],
  tech: [
    "Rhinoceros 3D",
    "Grasshopper",
    "Python",
    "Unreal Engine 4",
    "Photoshop",
    "Illustrator",
    "3D printing",
    "Laser Cutting",
    "Spraying and Painting",
  ],
  path: "/studio602",
  author: ["Jessica Yuan Ma", "Yuheng Ouyang"],
  instructor: ["Nathan Hume"],
  pageBackgroundColor: "#f6f6f6",
  content: [
    {
      type: "image",
      value: r1,
      classes: "full nomargin",
    },
    {
      type: "paragraph",
      value:
        "The project is a bank headquarter. Trying to break the solemn and detached impression of banks, we introduced overlapping systems to make the boundary vague and create a field with a gradient that welcomes visitors. A Frame can define the domain of volume. When the frame is completely covered by surfaces, the distinction of interior and exterior is obvious and the volume is totally solid. However, by starting to detach the surface from the frame, the separated space begins to merge and the boundary of solid becomes obscure. At a certain stage, the solid disintegrates into smaller semisolid fragments and the relationship of interior and exterior will not be told by inside or outside the solid frame, but by being surrounded by surfaces or not. The single, global alteration from outside to inside is substituted by a continuous shift of local conditions. The ability to define fields varies with nature of the system. The difference of material and elevation on the landscape implies the most nuanced change from publicity to privacy. The array of columns, on the other hand, creates a field with openness but slightly intensified by the column's height, density, and orderliness. The frame and surfaces begin to tell the shape of the building, but the volume is porous that the external world can still permeate into the internal through the gaps between surfaces. The pods, as the final system that defines the interior, creates the strongest and solid spaces for the bank and acts as the singular spots for visitors' journey. The surfaces of the building are built by colored tiles, which camouflages the building to weaken its volumetric properties and strengthen the visionary hallucination that obscures the building into phenomena.",
    },
    {
      type: "image",
      value: map,
      classes: "quarter",
    },
    {
      type: "image",
      value: dia1,
      classes: "half",
    },
    {
      type: "image",
      value: dia2,
      classes: "half",
    },
    {
      type: "image",
      value: plan,
      classes: "half",
    },
    {
      type: "image",
      value: section,
      classes: "half",
    },
    {
      type: "image",
      value: cut,
      classes: "quarter3",
    },
    {
      type: "image",
      value: cover,
      classes: "half",
    },
    {
      type: "image",
      value: r2,
      classes: "half",
    },
    {
      type: "image",
      value: r3,
      classes: "half",
    },
    {
      type: "image",
      value: m1,
      classes: "half",
    },
    {
      type: "image",
      value: m2,
      classes: "half",
    },
    {
      type: "image",
      value: m3,
      classes: "half",
    },
  ],
};
