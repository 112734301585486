import React from "react";
import { withMouse } from "../Contexts/MouseWrapper";
import { withPosition } from "./PositionContext";

const maxRotationY = 160;
const maxMouseTilt = 16;

const calcMouseTilt = ({ x, y }) => {
  const mouseRotateX = -maxMouseTilt * 2 * y;
  const mouseRotateY = maxMouseTilt * 2 * x;
  return { mouseRotateX, mouseRotateY };
};

class Rotated extends React.Component {
  shouldComponentUpdate() {
    return this.props.position.live;
  }

  render() {
    const {
      mousePosition,
      children,
      position: { relY },
    } = this.props;
    const { mouseRotateX, mouseRotateY } = calcMouseTilt(mousePosition);
    const scrollRotationY = (relY - 0.5) * maxRotationY * 2;
    return (
      <div
        style={{
          transform: `rotateX(${mouseRotateX}deg) rotateY(${
            mouseRotateY + scrollRotationY
          }deg)`,
          transformStyle: "preserve-3d",
        }}
      >
        {children}
      </div>
    );
  }
}

export default withPosition(withMouse(Rotated));
