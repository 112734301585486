import cover from "./lanB2.jpg";

export default {
  name: "Laminin -> Collagen IV",
  cover,
  hue: 278,
  desc: "genetic laboratory research",
  type: ["graduate thesis", "independent"],
  author: ["Yuheng Ouyang", "Jianli Dai"],
  instructor: ["‪Jose C Pastor-Pareja"],
  tech: [
    "CRISPR / Cas-9",
    "confocal microscopy imaging",
    "PCR",
    "electrophoresis",
    "DNA extraction",
    "DNA sequencing",
    "drosophila breeding",
  ],
};
