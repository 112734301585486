import React from "react";
import styled from "styled-components";

export const LinkButton = styled.a`
  color: transparent;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  margin-top: 32px;
  font-size: 16px;
  /* padding: 18px;
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 128px; */
  text-transform: capitalize;
`;

const VideoFrame = styled.video.attrs({
  autoPlay: true,
  loop: true,
  muted: true,
})`
  width: 100%;
`;

const ImageFrame = styled.img`
  margin-top: 32px;
`;

const BaseText = styled.p`
  font-family: "Roboto", sans-serif;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
`;

const Paragraph = styled(BaseText)`
  margin-top: 32px;
  font-size: 1em;
  line-height: 1.58;
  font-weight: 400;
  width: 80%;
  @media (min-width: 960px) {
    width: 46%;
  }
`;

const Caption = styled(BaseText)`
  font-size: 0.72em;
  text-align: center;
  line-height: 1.58;
  font-weight: 500;
`;

const PageItem = ({ item, project }) => {
  const { type, name, value, classes } = item;
  switch (type) {
    case "link":
      return (
        <LinkButton
          key={name}
          target="_blank"
          href={value}
          style={{
            background: `linear-gradient(36deg, ${project.colorGradient.start}, ${project.colorGradient.end})`,
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
          }}
        >
          {name}
        </LinkButton>
      );
    case "image":
      return <ImageFrame key={name} src={value} className={classes} />;
    case "paragraph":
      return (
        <Paragraph key={name} className={classes}>
          {value}
        </Paragraph>
      );
    case "caption":
      return <Caption key={name}>{value}</Caption>;
    default:
      return null;
  }
};

export default PageItem;
