import React from "react";
import { Route, Switch } from "react-router-dom";
import { Overmind, RouterConsumer } from "./Contexts";
import Home from "./Home";
import Page, { NotFound } from "./Page/";
import projects from "../assets/projects";
import "../assets/styles.css";

const App = () => (
  <Overmind>
    <RouterConsumer>
      {() => (
        <Switch>
          <Route exact path="/" component={Home} />
          {projects.map(
            (project) =>
              project.path && (
                <Route
                  path={project.path}
                  key={project.name}
                  render={(props) => <Page project={project} {...props} />}
                />
              )
          )}
          <Route component={NotFound} />
        </Switch>
      )}
    </RouterConsumer>
  </Overmind>
);

export default App;
