import cover from "./_RoofPlan.jpg";

export default {
  name: "peel / weave",
  cover,
  desc: "use descriptive actions to define architectural spaces",
  type: ["student work", "independent"],
  tech: ["Autodesk Maya", "Photoshop", "Illustrator"],
  author: ["Yuheng Ouyang"],
  instructor: ["Abigail Coover Hume"],
};
