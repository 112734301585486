import React from 'react';
import { withRouter } from 'react-router-dom';

export const {
  Consumer: RouterConsumer,
  Provider: RouterProvider,
} = React.createContext();

const RouterReceiver = props => {
  const { children, location } = props;
  return <RouterProvider value={location}>{children}</RouterProvider>;
};

export const RouterWrapper = withRouter(RouterReceiver);
