import cover from "./gif.gif";

export default {
  name: "cube subdiv",
  cover,
  desc: "recursive subdivision tool guided by attractor curves",
  author: ["Yuheng Ouyang"],
  type: ["student work", "independent"],
  tech: ["C#", "Rhinoceros 3D", "Keyshot"],
};
