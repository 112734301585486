import React from "react";
import styled from "styled-components";
import { CSSTransition } from "react-transition-group";
import { withIndex } from "../Contexts";
import projects from "../../assets/projects";
import BackgroundItem from "./BackgroundItem";

const Base = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: black;
  z-index: -1;
`;

const Background = ({ index }) => (
  <Base>
    {projects.map((project, i) => (
      <CSSTransition
        in={index.index === i}
        key={project.name}
        classNames="background"
        timeout={2400}
        unmountOnExit
      >
        <BackgroundItem project={project} index={index} />
      </CSSTransition>
    ))}
  </Base>
);

export default withIndex(Background);
