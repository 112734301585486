import cover from "./Shoe-02.jpg";

export default {
  name: "dancing shoe",
  cover,
  desc: "transforming shoes into on-the-go dance pad",
  type: ["student work", "cooperative"],
  instructor: ["Carla Diana"],
  author: ["Yihui Gan", "Cherag Bhagwagar", "Yuheng Ouyang"],
  tech: ["Arduino", "Prototyping", "Storyboard", "Illustrator"],
};
