import cover from "./ani.mp4";
import dd from "./dd.png";
import d301 from "./ERDY_DIAGRAM_blackBG.jpg";
import ele from "./ElevationKai.png";
import ddTop from "./ddTop.png";
import p1 from "./p1.jpg";
import p2 from "./p2.jpg";
import p3 from "./p3.jpg";
import p4 from "./p4.jpg";
import r1 from "./Exterior.jpg";
import r2 from "./Exterior2.jpg";
import r3 from "./SROInterior.jpg";
import r4 from "./BackView.jpg";

export default {
  name: "solar shell",
  cover,
  coverType: "video",
  desc:
    "a living machine-like architecture trying to capture sunlight and protect people",
  type: ["student work", "independent"],
  author: ["Yuheng Ouyang"],
  instructor: ["Scott Erdy"],
  tech: [
    "Rhinoceros 3D",
    "Grasshopper",
    "Python",
    "V-Ray",
    "Photoshop",
    "Illustrator",
    "3D printing",
    "Laser Cutting",
    "Spraying and Painting",
  ],
  path: "/solarshell",
  pageBackgroundColor: "black",
  content: [
    {
      type: "image",
      value: dd,
      classes: "full",
    },
    {
      type: "paragraph",
      classes: "white",
      value:
        "The Divine Lorraine Hotel was once a luxury hotel designed for the wealthy elite. In 1948, it was acquired by the Reverend Major J. Divine, who is a 1930's civil rights leader and founder of the Cooperative Economic Plan. He transformed the Divine Lorraine to a habitat of the poor, and a place to practice his Whole-person approach as a part of the Civil Rights Movement. The project attempts to practice the Whole-person approach in a more autonomous and sustainable way. The businesses before were efficient through ordinary means, making them vulnerable to competition. In order to establish its own critical competitiveness, the project pushes its solar orientation to an extreme. Standing on the south side of the Divine Lorraine Hotel with no high-rise buildings blocking sunlight, the building takes advantage of the energy that is gathered from the sun in order to self-supply the whole project. This act reduces the living expenses significantly. Paradoxically, this efficiency is achieved by the employment of sophisticated and expensive sun-harvesting technology purchased through wealthy donors, expiating their capitalistic exploitation of the environment.",
    },
    {
      type: "image",
      value: ddTop,
      classes: "half",
    },
    {
      type: "image",
      value: d301,
      classes: "half",
    },
    {
      type: "image",
      value: ele,
      classes: "quarter3",
    },
    {
      type: "image",
      value: p4,
      classes: "half",
    },
    {
      type: "image",
      value: p2,
      classes: "half",
    },
    {
      type: "image",
      value: p3,
      classes: "half",
    },
    {
      type: "image",
      value: p1,
      classes: "half",
    },
    {
      type: "image",
      value: r1,
      classes: "half",
    },
    {
      type: "image",
      value: r2,
      classes: "half",
    },
    {
      type: "image",
      value: r3,
      classes: "half",
    },
    {
      type: "image",
      value: r4,
      classes: "full",
    },
  ],
};
