import React from "react";
import styled from "styled-components";
import PageItem, { LinkButton } from "./PageItem";
import ScrollToTop from "../shared/ScrollToTop";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
`;

const Back = styled(LinkButton)`
  margin-bottom: 64px;
  cursor: pointer;
`;

const Page = (props) => {
  const { history, project } = props;
  return (
    <Wrapper style={{ backgroundColor: project.pageBackgroundColor }}>
      <ScrollToTop />
      {project.content.map((item) => (
        <PageItem key={item.value} item={item} project={project} />
      ))}
      <Back
        onClick={() => {
          history.goBack();
        }}
        style={{
          background: `linear-gradient(36deg, ${project.colorGradient.end}, ${project.colorGradient.start})`,
          backgroundClip: "text",
          WebkitBackgroundClip: "text",
        }}
      >
        Back
      </Back>
    </Wrapper>
  );
};

export default Page;
