import React from 'react';

export const {
  Consumer: ScrollConsumer,
  Provider: ScrollProvider,
} = React.createContext();

const sensitivity = 128;

export class ScrollWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.accDiff = 0;
  }

  state = { scrollY: 9999, scrollingDown: false };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const maxY = document.documentElement.scrollHeight - window.innerHeight;
    const curY = window.scrollY;
    let scrollingDown = this.state.scrollingDown;
    if (curY <= 0 || maxY == 0) {
      scrollingDown = false;
    } else if (curY >= maxY) {
      scrollingDown = true;
    } else {
      const curDiff = curY - this.state.scrollY;
      if (this.accDiff < 0 === curDiff < 0) {
        this.accDiff += curDiff;
        if (Math.abs(this.accDiff) > sensitivity) {
          scrollingDown = curDiff > 0;
        }
      } else {
        this.accDiff = curDiff;
      }
    }
    this.setState({ scrollY: curY, scrollingDown });
  };

  render() {
    const { children } = this.props;
    return <ScrollProvider value={this.state}>{children}</ScrollProvider>;
  }
}

export const withScroll = Component => props => (
  <ScrollConsumer>
    {scrollInfo => <Component scroll={scrollInfo} {...props} />}
  </ScrollConsumer>
);
