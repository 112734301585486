import cover from "./cover.mp4";

export default {
  name: "robo store",
  cover,
  coverType: "video",
  desc:
    "customer-facing website for real-time data visualization and store exploration",
  type: ["commercial", "independent"],
  tech: [
    "react",
    "redux",
    "redux-thunk",
    "react-router",
    "flow-typed",
    "RESTful API",
    "D3.js",
    "Canvas",
    "material-ui",
    "yarn",
    "parcel",
  ],
  author: ["Erin Yi Yang", "Yuheng Ouyang"],
};
