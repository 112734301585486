import React from "react";
import styled from "styled-components";
import Rotated from "./Rotated";
import { PositionMarker } from "./PositionContext";
import Item from "./Item";

const Margined = styled.div`
  margin: 32px;
`;

const Entry = (props) => (
  <Margined>
    <PositionMarker>
      <Rotated>
        <Item {...props} />
      </Rotated>
    </PositionMarker>
  </Margined>
);

export default Entry;
