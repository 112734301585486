import cover from "./702r2.jpg";
import r1 from "./r1.jpg";

export default {
  name: "ca.alter",
  cover,
  desc:
    "extend elementary cellular automaton rulesets to 2d plane, and test generated geometries",
  type: ["student work", "independent"],
  tech: ["Rhinoceros 3D", "Grasshopper", "Python", "Keyshot"],
  author: ["Yuheng Ouyang"],
  instructor: ["Ezio Blasetti"],
  path: "/caalter",
  pageBackgroundColor: "#e8e8e8",
  content: [
    {
      type: "image",
      value: cover,
      classes: "full nomargin",
    },
    {
      type: "image",
      value: r1,
      classes: "full nomargin",
    },
  ],
};
