import React from "react";
import styled from "styled-components";
import { withScroll } from "../Contexts";

const Wrapper = styled.div`
  padding-bottom: 12px;
  position: fixed;
  bottom: 0;
  right: 0;
  transition: transform 0.4s, opacity 0.4s;
  padding-right: 16px;
`;

const Text = styled.span`
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 12px;
  color: transparent;
  margin-left: 16px;
  background: linear-gradient(
    30deg,
    ${(props) => props.gradient.start},
    ${(props) => props.gradient.end}
  );
  background-clip: text;
  -webkit-background-clip: text;
`;

class Footer extends React.Component {
  shouldComponentUpdate(nextProp) {
    return this.props.scroll.scrollingDown !== nextProp.scroll.scrollingDown;
  }
  render() {
    const {
      gradient,
      scroll: { scrollingDown },
    } = this.props;
    return (
      <Wrapper
        style={{
          transform: `translateY(${scrollingDown ? 8 : 0}px)`,
          opacity: scrollingDown ? 0 : 0.64,
        }}
      >
        {/* <a
          href="https://github.com/govizlora/govizlora.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            style={{ position: "relative", bottom: -4 }}
          >
            <defs>
              <linearGradient id="gradient" x1="30%" y1="0%" x2="70%" y2="100%">
                <stop offset="0%" stopColor={gradient.start} />
                <stop offset="100%" stopColor={gradient.end} />
              </linearGradient>
            </defs>
            <path
              fill="url(#gradient)"
              fillRule="evenodd"
              d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0 0 16 8c0-4.42-3.58-8-8-8z"
            />
          </svg>
        </a> */}
        <Text gradient={gradient}>Last updated: 2020.07.13</Text>
      </Wrapper>
    );
  }
}

export default withScroll(Footer);
