export const lightColor = () => `hsl(${Math.random() * 360}, 64%, 64%)`;
export const darkColor = () => `hsl(${Math.random() * 360}, 16%, 36%)`;
export const lightGradient = (h) => {
  const hue = h || Math.random() * 360;
  const flip = Math.random() > 0.5 ? 1 : -1;
  return {
    start: `hsl(${hue + 72 * flip}, 100%, 72%)`,
    mid: `hsl(${hue + 36 * flip}, 100%, 64%)`,
    end: `hsl(${hue}, 100%, 64%)`,
  };
};
