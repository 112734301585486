import React from "react";
import { withScroll } from "../Contexts";

export const {
  Consumer: PositionConsumer,
  Provider: PositionProvider,
} = React.createContext();

class ScrollTriggered extends React.Component {
  constructor(props) {
    super(props);
    this.scrollRef = React.createRef();
    this.relY = 1;
    this.live = true;
  }

  componentDidMount() {
    this.updateLive();
    if (this.live) {
      this.forceUpdate();
    }
  }

  shouldComponentUpdate() {
    // revive the dead when it is in the scope
    if (!this.live) {
      this.updateLive();
      return this.live;
    }
    this.updateLive();
    // even if a live one goes out of the scope
    // we need to return true to update the context
    // so it won't update next time
    return true;
  }

  updateLive = () => {
    const rect = this.scrollRef.current.getBoundingClientRect();
    const cenY = rect.top + rect.height / 2;
    this.relY = cenY / window.innerHeight;
    this.live = this.relY > 0 && this.relY < 1;
  };

  render() {
    const { children } = this.props;
    return (
      <div ref={this.scrollRef}>
        <PositionProvider value={{ relY: this.relY, live: this.live }}>
          {children}
        </PositionProvider>
      </div>
    );
  }
}

export const PositionMarker = withScroll(ScrollTriggered);

export const withPosition = (Component) => (props) => (
  <PositionConsumer>
    {(position) => <Component position={position} {...props} />}
  </PositionConsumer>
);
