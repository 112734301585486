import React from 'react';

export const {
  Consumer: MouseConsumer,
  Provider: MouseProvider,
} = React.createContext();

export class MouseWrapper extends React.Component {
  state = { x: 0, y: 0 };

  componentDidMount() {
    window.addEventListener('mousemove', this.handleMove);
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.handleMove);
  }

  handleMove = (e) => {
    const x = e.clientX / window.innerWidth - 0.5;
    const y = e.clientY / window.innerHeight - 0.5;
    this.setState({ x, y });
  };

  render() {
    const { children } = this.props;
    return <MouseProvider value={this.state}>{children}</MouseProvider>;
  }
}

export const withMouse = Component => props => (
  <MouseConsumer>
    {mousePosition => <Component mousePosition={mousePosition} {...props} />}
  </MouseConsumer>
);
