import React from 'react';

export const {
  Consumer: IndexConsumer,
  Provider: IndexProvider,
} = React.createContext();

export class IndexWrapper extends React.Component {
  state = { index: -1 };

  setIndex = (index) => {
    this.setState({ index });
  };

  render() {
    const { children } = this.props;
    const { index } = this.state;
    return (
      <IndexProvider value={{ setIndex: this.setIndex, index }}>
        {children}
      </IndexProvider>
    );
  }
}

export const withIndex = Component => props => (
  <IndexConsumer>
    {index => <Component index={index} {...props} />}
  </IndexConsumer>
);
