import { lightGradient } from "../../shared/randomColor";
import studio602 from "./studio602";
import hellenikonSolid from "./hellenikonSolid";
import solarShell from "./solarShell";
import caAlter from "./caAlter";
import caShell from "./caShell";
import studio701 from "./studio701";
import peelWeave from "./peelWeave";
import veinPetal from "./veinPetal";
import timeout from "./timeout";
import inflatingPathfinder from "./inflatingPathfinder";
import worldview from "./worldview";
import dancingShoe from "./dancingShoe";
import cubeSubdiv from "./cubeSubdiv";
import laminin from "./lamini";
import instore3d from "./instore3d";
import roboStore from "./roboStore";
import papercyan from "./papercyan";

const projects = [
  hellenikonSolid,
  caShell,
  caAlter,
  studio602,
  solarShell,
  studio701,
  roboStore,
  instore3d,
  peelWeave,
  veinPetal,
  timeout,
  inflatingPathfinder,
  papercyan,
  worldview,
  dancingShoe,
  cubeSubdiv,
  laminin,
];

projects.forEach((project) => {
  Object.assign(project, { colorGradient: lightGradient(project.hue) });
});

export default projects;
