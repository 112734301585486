import cover from "./cover.jpg";

export default {
  name: "inflating pathfinder",
  cover,
  desc: "creating inflated space",
  type: ["architectural design", "student work", "independent"],
  tech: ["Rhinoceros 3D", "Grasshopper", "Kangaroo Physics", "Keyshot"],
  author: ["Yuheng Ouyang"],
  instructor: ["Michael Loverich"],
};
