import cover from "./poi.jpg";

export default {
  name: "papercyan",
  cover,
  hue: 190,
  desc: "CSS theme for an open source game infoviewer",
  type: ["open source", "independent"],
  tech: ["CSS", "Less", "Bootstrap", "Gulp"],
  author: ["Yuheng Ouyang"],
};
