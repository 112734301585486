import React from "react";
import styled from "styled-components";
import Entry from "./Entry";
import { withIndex } from "../Contexts";
import projects from "../../assets/projects";
import Background from "./Background";
import Footer from "./Footer";
import { lightGradient } from "../../shared/randomColor";

const gradient = lightGradient();

const Wrapper = styled.div`
  padding-top: calc(50vh - 64px);
  padding-bottom: calc(50vh - 64px);
  display: flex;
  flex-direction: column;
  align-items: center;
  /* strange behavior on ios safari without this line. */
  transform: translateZ(0px);
`;

class Main extends React.Component {
  render() {
    const { index } = this.props;
    const handleClick = () => {
      if (index.index !== -1) {
        index.setIndex(-1);
      }
    };
    return (
      <React.Fragment>
        <Background />
        <Wrapper onTouchStart={handleClick}>
          {projects.map((project, i) => (
            <Entry key={project.name} project={project} index={i} />
          ))}
        </Wrapper>
        <Footer gradient={gradient} />
      </React.Fragment>
    );
  }
}

export default withIndex(Main);
