import cover from "./it.jpg";

export default {
  name: "_timeout",
  cover,
  desc: "freezed swarm",
  type: ["installation art", "student work", "cooperative"],
  tech: ["Rhinoceros 3D", "Keyshot"],
  author: [
    "Allison Koll",
    "Yuheng Ouyang",
    "Alex Saroki",
    "Yu Zhou",
    "Natasha Sanjaya",
  ],
  instructor: ["Michael Loverich"],
};
