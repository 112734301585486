import cover from "./501_vvfar.jpg";

export default {
  name: "vein / petal",
  cover,
  desc: "a material and physical study",
  type: ["student work", "independent"],
  tech: ["Rhinoceros 3D", "Grasshopper", "Python", "Keyshot"],
  author: ["Yuheng Ouyang"],
  instructor: ["Danielle Willems"],
};
