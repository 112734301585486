import cover from "./701r1.jpg";
import axo from "./axo.png";
import d from "./d.png";
import p1 from "./p1.jpg";
import p2 from "./p2.jpg";
import pd from "./pd.png";
import pm from "./pm.png";
import r2 from "./r2.jpg";
import r3 from "./r3.jpg";
import sd from "./sd.png";
import sm from "./sm.png";

export default {
  name: "studio 701",
  cover,
  desc: "architectural design pushed by extreme weather",
  type: ["student work", "cooperative"],
  tech: [
    "Autodesk Maya",
    "Unreal Engine 4",
    "Rhinoceros 3D",
    "Grasshopper",
    "Python",
    "Photoshop",
    "3D printing",
  ],
  author: ["Jiateng Wang", "Yuheng Ouyang"],
  instructor: ["Tom Verebes"],
  path: "/studio701",
  pageBackgroundColor: "#f6f6f6",
  content: [
    {
      type: "image",
      value: cover,
      classes: "full nomargin",
    },
    {
      type: "paragraph",
      value:
        "Located at the tip of the peninsula, the project confronts ferocious subtropical nature forces such as hurricane, rainstorm, and tides, which could cause huge losses and impede everyday activities. However, those threats are also invaluable features that bring adaption to the uniform artificial site from sea reclamation. The need of High-density communities in Shenzhen and the unwelcoming force of nature push the project to internalize the activity space and bundle individual buildings into communities. Each community is capable of sustaining all living needs with differentiated blocks. The circulation inside the community is supported by a shared podium space which connects all buildings, and cantilever corridors link buildings where frequent commute happens. To minimize the impact of tide and waves, the ground was lifted away from the sea, only leaving arrays of legs standing in the water. The organization of legs is inspired by wave-damping instruments, with small and round legs at the frontline and huge, sharp legs staying behind. Above the lifted grounds, high-rise buildings get their outline shaped according to wind direction and daylighting requirements. By lifting the ground, more light is introduced into the interior space from the side of podiums. Since most of the everyday-life activities are internalized inside the buildings, the whole exterior of the project gains the potential to be well-developed natural landscape. Through the opened seam which extends from buildings to podiums, interior and exterior are subtly merged. The lifting ground also brings diversified opportunities towards the water. With a differentiated height of the podiums, the living condition varies from immersed-in-water beaches to high-rise commercial blocks which are a hundred feet away from water.",
    },
    {
      type: "image",
      value: axo,
      classes: "half",
    },
    {
      type: "image",
      value: d,
      classes: "quarter3",
    },
    {
      type: "image",
      value: pm,
      classes: "quarter3",
    },
    {
      type: "image",
      value: sm,
      classes: "quarter3",
    },
    {
      type: "image",
      value: sd,
      classes: "quarter3",
    },
    {
      type: "image",
      value: r2,
      classes: "half",
    },
    {
      type: "image",
      value: r3,
      classes: "half",
    },
    {
      type: "image",
      value: p1,
      classes: "half",
    },
    {
      type: "image",
      value: p2,
      classes: "half",
    },
  ],
};
